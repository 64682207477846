
import Vue from "vue";
import Main from "@/components/Main.vue";
import { auth, check } from "../auth/auth";
import router from "@/router";
import { Amplify } from "aws-amplify";
import { cloudConfiguration } from "@/cloud-config";

Amplify.configure(cloudConfiguration);

export default Vue.extend({
    name: "SignInView",
    components: {
        Main,
    },
    methods: {
        signUp: auth,
        check: async () => {
            const skata = await check();
            if (skata) router.push("/sub1");
        },
    },
});
