import { Amplify, Auth } from "aws-amplify";
import { cloudConfiguration } from "@/cloud-config";
import router from "@/router";

Amplify.configure(cloudConfiguration);

export const auth = async () => {
    const username = "giotopoulos@iridalabs.com";
    const password = "Castlevania1!";
    const user = await Auth.signIn({
        username,
        password,
    });
    router.push("/home");
    try {
        await Auth.currentSession();
    } catch (error) {
        console.log("skatules");
    }
};

export const signout = async () => {
    await Auth.signOut({});
    localStorage.removeItem("sessionToken");
    router.push("/");
};

export const check = async () => {
    try {
        const skata = await Auth.currentSession();
        return true;
    } catch (error) {
        console.error("User is not logged in.");
        return false;
    }
};
