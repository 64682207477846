import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import SignInView from "../views/SignInView.vue";
import HomepageView from "../views/HomepageView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "signin",
        component: SignInView,
    },
    {
        path: "/home",
        name: "home",
        component: HomepageView,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
