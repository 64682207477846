export const cloudConfiguration = {
    aws_project_region: "eu-central-1",
    aws_cognito_identity_pool_id: "eu-central-1:71070b6d-abd6-48f6-b195-1c72dba31fd7",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_UOEAnH518",
    aws_user_pools_web_client_id: "21rp9bfn12lfri6qr7vpcp7nmr",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OPTIONAL",
    aws_cognito_mfa_types: ["TOTP"],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_user_files_s3_bucket: "percvai1155308-main",
    aws_user_files_s3_bucket_region: "eu-central-1",
    aws_cloud_logic_custom: [
        {
            name: "percvai1Core",
            endpoint: "https://5zezv36777.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1CoreLicense",
            endpoint: "https://wob52kw6qe.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1DataEngineEntry",
            endpoint: "https://7vfw8tzied.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1DataTrigger",
            endpoint: "https://dke5z872nj.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1HistoricalData",
            endpoint: "https://sr3973gtp3.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1Organization",
            endpoint: "https://52x78pbu61.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1OrganizationUser",
            endpoint: "https://99zc4dlitj.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1ProblemFormulationEntry",
            endpoint: "https://qm7egzhb0c.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1Project",
            endpoint: "https://hpkapj9l2i.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1User",
            endpoint: "https://5dr7kj9bl9.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1VirtualFile",
            endpoint: "https://yfvfq5paw9.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1NotificationTrigger",
            endpoint: "https://vg6dvacoz3.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1Dataset",
            endpoint: "https://fmbrt4d8oe.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1File",
            endpoint: "https://dc4660e5ib.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1TrainedModel",
            endpoint: "https://58yow8ur30.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
        {
            name: "percvai1DeployedModel",
            endpoint: "https://yzbocvtcw3.execute-api.eu-central-1.amazonaws.com/main",
            region: "eu-central-1",
        },
    ],
    aws_mobile_analytics_app_id: "7033ffe7327a41e5a3a90d944b766443",
    aws_mobile_analytics_app_region: "eu-central-1",
};
