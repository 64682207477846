
import Vue from "vue";
import Main from "@/components/Main.vue";
import { check, signout } from "../auth/auth";
import router from "@/router";
import { Amplify } from "aws-amplify";
import { cloudConfiguration } from "@/cloud-config";

Amplify.configure(cloudConfiguration);

export default Vue.extend({
    name: "HomepageView",
    components: {
        Main,
    },
    methods: {
        signOut: signout,
        check: async () => {
            const skata = await check();
            if (skata) window.location.href = "https://www.sub1.main.percv.ai/";
            else router.push("/");
        },
    },
});
